import React, { useState } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import { Text } from '@latitude/text';
import NumberFormat from 'react-number-format';
import { CALCULATOR_TRACKING } from '../../utils/constants';
import './_currency-input.scss';

const component = 'currency-input';

const CurrencyInput = props => {
  const {
    name,
    className,
    labelText,
    value,
    placeholder,
    showErrorMessage,
    showError,
    onValueChange,
    onChange,
    maxLength,
    prefix,
    allowNegative = true,
    type,
    widget = false,
    disableDefaultEventTracking = false
  } = props;

  /**
   * Used to store previous amount value, which then allows us
   * to trigger Tealium tracking only when this use case is applicable
   */
  const [amount, setAmount] = useState(value);

  // Update styling props values
  const extraClasses = showError ? ' error' : '';
  const labelStyle = showError ? `${component}__label-error` : '';
  const widgetClass = widget ? `${component}--widget` : '';

  return (
    <AnalyticsContext.Consumer>
      {([analytics]) => (
        <div
          className={`${component} input-wrap ${className ||
            ''} ${widgetClass}`}
        >
          <div className={labelStyle}>
            {/* eslint-disable-next-line */}
            <Text>{labelText && <label>{labelText}</label>}</Text>
          </div>

          <div className="pos-rel">
            {prefix && (
              <span className={`${component}__prepend`}>{prefix}</span>
            )}

            <NumberFormat
              className={`${component}__field styled-input-wrap__input dollar ${extraClasses}`}
              name={name}
              thousandSeparator
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              decimalScale={0}
              allowNegative={allowNegative}
              onValueChange={onValueChange}
              onChange={onChange}
              onBlur={event => {
                setAmount(value);
                if (disableDefaultEventTracking !== true) {
                  if (value !== amount) {
                    if (trackEvent) {
                      trackEvent(analytics, {
                        category: CALCULATOR_TRACKING.CATEGORY,
                        action: CALCULATOR_TRACKING.ACTION,
                        label: labelText,
                        location: CALCULATOR_TRACKING.LOCATION,
                        value: event.currentTarget?.value
                      });
                    }
                  }
                }
              }}
              type={type}
            />
            {showError && (
              <div className={`${component}__error-container`}>
                <div className={`${component}__error-message`}>
                  {showErrorMessage}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </AnalyticsContext.Consumer>
  );
};

export default CurrencyInput;
